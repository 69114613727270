<template lang="pug">
section#home
    header(:style="{ background: `url(${headerImg}) top right no-repeat` }")
        h3.animated.fadeIn.greeting Hey there, {{ username }} ✌
        p.animated.fadeIn.welcome Welcome to your SchemaDB dashboard. You can manage your schema versions and API tokens here.

        .usage.animated.fadeIn
            //- label.inline-block Usage
            span.user-type.inline-block
                i.icon.material-icons grade
                | Beta tester
            p {{ schemasCount }} / {{ quota }} schema versions used

    h2.animated.fadeIn My schemas
    
    .onboarding.animated.fadeIn(v-if="!schemas || !schemas.length")
        p You don't have any schema yet. Have a look at <a href="https://schemadb.github.io/node-sdk/" target="_blank">our docs</a> to get started 🙌
        img(:src="onboardingImg")

    .schema-container(v-if="schemas && schemas.length")
        .schema-w(v-for="s in schemas", :key="s ? s['_id'] : undefined")
            Schema(:schema="s")

</template>

<script>
import Schema from '@/components/Schema';
import { getSchemas } from '@/services/schema-service';
import { getUserDetails } from '@/services/user-service';

export default {
    components: { Schema },
    data: () => ({
        schemas: [ null, null, null, null ],
        user: undefined
    }),
    computed: {
        onboardingImg: () => require('@/assets/img/onboarding.png'),
        headerImg: () => require('@/assets/img/header.png'),
        quota: function() {
            if (!this.user) {
                return '--';
            }
            return this.user['quota']['schemas'];
        },
        schemasCount: function() {
            if (!this.schemas || !this.schemas[0]) {
                return '--';
            }

            return this.schemas.reduce((acc, schema) => acc + schema['records'].length, 0);
        },
        username: function() {
            if (!this.user || !this.user['name']) { return '--'; }
            return this.user['name'].split(' ')[0];
        }
    },
    async mounted() {
        this._setUserInfo();
        this._updateSchemas();
    },
    methods: {
        async _updateSchemas() {
            try {
                const schemas = await getSchemas();
                this.schemas = schemas;
            } catch (error) {
                this.schemas = undefined;
                console.error(error);
            }
        },
        _addSchemaVersion(newSchema) {
            try {
                const schema = this.schemas.filter(s => {
                    return s['name'] === newSchema['definition']['name']
                        && s['namespace'] === newSchema['definition']['namespace'];
                })[0];
                if (schema) {
                    schema['last_updated'] = newSchema['created_at'];
                    schema.records.push(newSchema);

                    this.schemas = this.schemas.sort((a, b) => {
                        return new Date(b['last_updated']) - new Date(a['last_updated']);
                    });
                } else {
                    this.schemas = [ {
                        name: newSchema['definition']['name'],
                        namespace: newSchema['definition']['namespace'],
                        last_updated: newSchema['created_at'],
                        records: [ newSchema ]
                    }, ...this.schemas ];
                }
                
            } catch (error) {
                // ignored
            }
        },
        async _setUserInfo() {
            try {
                const userDetails = await getUserDetails();
                this.user = userDetails;

                this.sockets.subscribe(`new-schema::${this.user['username']}`, (newSchema) => {
                    this._addSchemaVersion(newSchema);
                });
            } catch (error) {
                this.user = undefined;
                console.error(error);
            }
        }
    }
};
</script>

<style scoped>

header {
    padding-bottom: var(--padding-large);
    padding-top: var(--padding-large);
    margin-bottom: var(--margin-large);
    border-bottom: 2px solid #f4f7fc;
    margin-top: calc(var(--padding-large) * -1);
}

header > * {
    position: relative;
    display: block;
    width: 50%;
}
header p.welcome {
    color: var(--text-color-grey);
    text-align: justify;
    font-size: var(--font-size-base);
    margin-bottom: var(--margin-large);
}

header h3 {
    font-size: 28px;
}

header .usage span.user-type {
    vertical-align: middle;
    text-transform: uppercase;
    font-size: var(--font-size-tiny);
    font-weight: 600;
    padding: 4px 8px;
    background: #242424;
    color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(64, 64, 64, .3);
}
header .usage span.user-type i {
    color: #fff;
    font-weight: normal;
    font-size: 11px;
    margin-right: 6px;
    top: 1px;
    position: relative;
}

header .usage p {
    color: var(--text-color-grey);
    font-size: var(--font-size-small);
}


h2 {
    font-size: var(--font-size-huge);
    margin-bottom: var(--margin-large);
}

.onboarding {
    width: 50%;
    padding-top: var(--padding-large);
    margin: 0 auto;
}

.onboarding img {
    position: relative;
    display: block;
    width: 100%;
    margin: auto;
}
.onboarding p {
    color: var(--text-color-grey);
    font-size: var(--font-size-base);
}

.schema-container {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
}

.schema-w {
    width: calc(33.33% - calc(calc(var(--padding-medium) * 2) / 3));
    margin-bottom: var(--padding-medium);
    margin-right: var(--padding-medium);
    vertical-align: top;
}

/* Responsive */
@media only screen and (max-width: 1200px) {
    header {
        padding: var(--padding-medium);
        padding-top: var(--padding-large);
        width: 100vw;
        margin-left: calc(var(--padding-medium) * -1);
    }
}

@media only screen and (min-width: 1001px) {
    .schema-w:nth-of-type(3n) {
        margin-right: 0;
    }
}

@media only screen and (max-width: 1000px) {
    h2 {
        font-size: var(--font-size-medium);
        margin-bottom: var(--margin-base);
    }
    .onboarding {
        width: 100%;
    }
    .onboarding img {
        max-width: 60vw;
    }
    .schema-w {
        width: calc(50% - var(--padding-medium));
        vertical-align: top;
    }

    .schema-w:nth-of-type(2n) {
        margin-right: 0;
    }
}

/* Responsive */
@media only screen and (max-width: 700px) {
    header {
        background: none !important;
        padding-bottom: var(--padding-small);
    }
    header > * {
        width: 100%;
    }
    header h3 {
        font-size: var(--font-size-medium);
    }
    header p.welcome {
        font-size: var(--font-size-small);
    }
    .onboarding {
        padding-top: 0;
    }
    .onboarding img {
        max-width: 100%;
    }
    .schema-w {
        width: 100%;
        margin-right: 0;
    }
}
</style>
