<template lang="pug">
    .wrapper
        router-link(v-if="schema", :to="{ name: 'schema-details', params: { namespace: schema['namespace'], schemaName: schema['name'] }}")
            .schema.animated.fadeIn
                header
                    .avatar.inline-block {{ schema['name'].substr(0,2) }}
                    .title.inline-block
                        p.name {{ schema['name'] }}
                        p.namespace {{ schema['namespace'] }}
                    i.icon.material-icons-outlined.inline-block more_horiz

                p.sub.last_updated(title="Last updated")
                    i.icon.material-icons-outlined.inline-block schedule
                    | {{ schema['last_updated'] }}
                p.sub.versions {{ schema['records'].length }} versions

        //- Content loader
        .schema.schema-skt(v-if="!schema")
            header
                .avatar.skt.inline-block
                .title.title.inline-block
                    p.skt.name
                    p.skt.namespace
            p.sub.last_updated.skt
            p.sub.versions.skt
    
</template>

<script>
export default {
    props: {
        schema: {
            type: Object
        }
    }
};
</script>

<style scoped>

* {
    --avatar-size: 40px;
    text-decoration: none;
}

.schema {
    padding: var(--padding-base);
    padding-top: calc(var(--padding-base) + 6px);
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(64, 64, 64, .08);

    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.schema:not(.schema-skt) {
    cursor: pointer;
}
.schema:not(.schema-skt):hover {
    box-shadow: 0 4px 12px 4px rgba(64, 64, 64, .1);
}

.avatar {
    width: var(--avatar-size);
    height: var(--avatar-size);
    border-radius: 100px;
    color: #fff;
    text-transform: uppercase;
    line-height: var(--avatar-size);
    text-align: center;
    font-size: 15px;
}
.avatar:not(.skt) {
    background: #ccc;
}

p {
    margin: 0;
}

.title {
    width: calc(90% - var(--avatar-size));
    padding-left: var(--padding-base);
}
p.name {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
}
p.namespace {
    font-size: var(--font-size-small);
    font-weight: 300;
    color: var(--text-color-grey);
    line-height: 22px;
}

header { 
    margin-bottom: var(--margin-large);
}

header > * {
    vertical-align: middle;
}
header i.icon {
    width: 10%;
    text-align: right;
    cursor: pointer;
    color: var(--text-color-grey);
    vertical-align: top;
    top: calc(var(--padding-base) * -.8);
    right: -4px;
}

.sub {
    font-family: monospace;
    font-size: var(--font-size-tiny);
    color: var(--text-color-grey);
}

.sub.last_updated {
    margin-bottom: 6px;
}

p.sub i.icon {
    font-size: 12px;
    color: var(--text-color-grey);
    line-height: 13px;
    margin-right: 4px;
    top: 2px;
}

.schema-skt {
    padding-top: calc(var(--padding-base) + 4px);
    border-color: #f6f7f8;
    border-width: 2px;
    box-shadow: none;
}
p.skt {
    height: 19px;
    width: 50%;
    margin-bottom: 6px;
}
header .title p.skt:last-of-type {
    height: 17px;
    width: 80%;
    margin-bottom: 0;
}
p.skt.sub.last_updated {
    height: 15px;
    margin-bottom: 6px;
    width: 60%;
}
p.skt.sub.versions {
    height: 13px;
    width: 20%;
    margin-bottom: 0;
}
</style>